import React from "react";
import styled from "styled-components";
import { About } from "../styles";
import Toggle from "./Toggle";
import { AnimateSharedLayout } from "framer-motion";
import { useScroll } from "./useScroll";
import { scrollReveal } from "../animation";

const FaqSection = () => {
  const [element, controls] = useScroll();
  return (
    <Faq
      variants={scrollReveal}
      ref={element}
      animate={controls}
      initial="hidden"
    >
      <h2>
        Any Questions <span>FAQ</span>
      </h2>
      <AnimateSharedLayout>
        <Toggle title="What degrees do I own">
          <div className="answer">
            <p>Associate Degree Software Development</p>
            <p>International Baccalaureate B2+</p>
          </div>
        </Toggle>

        <Toggle title="Jobs">
          <div className="answer">
            <p>(GMU) Global Marketing Unity, Major Capital (2021 - current)</p>
          </div>
        </Toggle>

        
        <Toggle title="My work">
          <div className="answer">
            <p>Private work you can find under "My Work" on top</p>
          </div>
        </Toggle>
        
        <Toggle title="What languages do I work with?">
          <div className="answer">
            <p>All my work is made in React.js!
            <br></br>
            <br></br>
            But i do have knowledge of other languages</p>
            <br></br>
            <p>HTML</p>
            <p>CSS, Bootstrap, Tailwind CSS</p>
            <p>PHP, Laravel</p>
            <p>Javascript, React, Vue, Angular</p>
            <p>C#, .NET, .CORE</p>
            <p>Software Testing</p>
            <p>Java</p>
            <p>C++</p>
            <p>Flutter</p>
          </div>
        </Toggle>
        {/* <Toggle title="Diferrent Payment Methods">
          <div className="answer">
            <p>Payment goes through paypal or Ideal.</p>
            <p>
             60% opfront 40% after.
            </p>
          </div>
        </Toggle> */}

      </AnimateSharedLayout>
    </Faq>
  );
};

const Faq = styled(About)`
  display: block;
  span {
    display: block;
  }
  h2 {
    padding-bottom: 2rem;
    font-weight: lighter;
  }
  .faq-line {
    background: #cccccc;
    height: 0.2rem;
    margin: 2rem 0rem;
    width: 100%;
  }
  .question {
    padding: 3rem 0rem;
    cursor: pointer;
  }
  .answer {
    padding: 2rem 0rem;
    p {
      padding: 1rem 0rem;
    }
  }
`;

export default FaqSection;
