import React from "react";
import styled from "styled-components";
//Images
import GLOW from "../img/glow-header.jpg";
import MJ from "../img/mj-header.png";
import PO from "../img/po-header.png";
import RO from "../img/RCkop.jpg";
import PMK from "../img/pmk.jpg";



//Animations
import { motion } from "framer-motion";
import {
  sliderContainer,
  slider,
  pageAnimation,
  fade,
  photoAnim,
  lineAnim,
} from "../animation";
import { useScroll } from "../components/useScroll";
import ScrollTop from "../components/ScrollTop";

const OurWork = () => {
  const [element, controls] = useScroll();
  const [element2, controls2] = useScroll();
  const [element3, controls3] = useScroll();

  return (
    <Work
      style={{ background: "#fff" }}
      exit="exit"
      variants={pageAnimation}
      initial="hidden"
      animate="show"
    >
      <motion.div variants={sliderContainer}>
        <Frame1 variants={slider}></Frame1>
        <Frame2 variants={slider}></Frame2>
        <Frame3 variants={slider}></Frame3>
        <Frame4 variants={slider}></Frame4>
      </motion.div>

      <Movie>
        <motion.h2 variants={fade}>GLOWFORYOU</motion.h2>
        <motion.div variants={lineAnim} className="line"></motion.div>
          <Hide>
            <a href='http://glow-foryou.nl'>
            <motion.img variants={photoAnim} src={GLOW} alt="GLOW" />
            </a>
          </Hide>
      </Movie>
      
      <Movie>
        <motion.h2 variants={fade}>Major Capital</motion.h2>
        <motion.div variants={lineAnim} className="line"></motion.div>
          <Hide>
            <a href='https://testing.majorcapital.com'>
            <motion.img variants={photoAnim} src={MJ} alt="MJ" />
            </a>
          </Hide>
      </Movie>

      <Movie>
        <motion.h2 variants={fade}>P. Overman Administratiekantoor B.V.</motion.h2>
        <motion.div variants={lineAnim} className="line"></motion.div>
          <Hide>
            <a href='https://www.petraoverman.nl'>
            <motion.img variants={photoAnim} src={PO} alt="PO" />
            </a>
          </Hide>
      </Movie>
      <Movie>
        <motion.h2 variants={fade}>Rudie Crossman (wip)</motion.h2>
        <motion.div variants={lineAnim} className="line"></motion.div>
          <Hide>
            <a href=''>
            <motion.img variants={photoAnim} src={RO} alt="RO" />
            </a>
          </Hide>
      </Movie>

      <Movie>
        <motion.h2 variants={fade}>PMK-Interiors (wip)</motion.h2>
        <motion.div variants={lineAnim} className="line"></motion.div>
          <Hide>
            <a href=''>
            <motion.img variants={photoAnim} src={PMK} alt="PMK" />
            </a>
          </Hide>
      </Movie>
      
      <ScrollTop />
    </Work>
    
  );
};

const Work = styled(motion.div)`
  min-height: 100vh;
  overflow: hidden;
  padding: 5rem 10rem;
  @media (max-width: 1300px) {
    padding: 2rem 2rem;
  }

  h2 {
    padding: 1rem 0rem;
  }
`;
const Movie = styled(motion.div)`
  padding-bottom: 10rem;

  .line {
    height: 0.5rem;
    background: #23d997;
    margin-bottom: 3rem;
  }
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;
const Hide = styled.div`
  overflow: hidden;
`;

//Frame Animation
const Frame1 = styled(motion.div)`
  position: fixed;
  left: 0;
  top: 10%;
  width: 100%;
  height: 100vh;
  background: #fffebf;
  z-index: 2;
`;
const Frame2 = styled(Frame1)`
  background: #ff8efb;
`;
const Frame3 = styled(Frame1)`
  background: #8ed2ff;
`;
const Frame4 = styled(Frame1)`
  background: #8effa0;
`;

export default OurWork;
