import React from "react";
import home1 from "../img/home1.png";
import { About, Description, Image, Hide, } from "../styles";
//Framer Motion
import { motion } from "framer-motion";
import { titleAnim, fade, photoAnim } from "../animation";
import Wave from "./Wave";

const directToTwitter = () => {
  window.location.href='https://twitter.com/DynamicCoding';
}
const directToFB = () => {
  window.location.href='https://www.facebook.com/Nigel-Overman-106822378413061';
}
const directToLI = () => {
  window.location.href='https://www.linkedin.com/in/nigel-overman-216b9b196/';
}


const AboutSection = () => {
  return (
    <About>
      <Description>
        <motion.div>
          <Hide>
            <motion.h2 variants={titleAnim}>I work to make</motion.h2>
          </Hide>
          <Hide>
            <motion.h2 variants={titleAnim}>
              your <span>dreams</span> come
            </motion.h2>
          </Hide>
          <Hide>
            <motion.h2 variants={titleAnim}>true.</motion.h2>
          </Hide>
        </motion.div>
        <motion.p  variants={fade}>
          My name is Nigel Overman <br></br>
          I'm a passionate hard working software developer
          </motion.p>
        <motion.p variants={fade}>
          Contact me for any website or app ideas that you have.
          <br></br><br></br>
          Email: <span>Nigel200019@gmail.com</span>
        </motion.p>
        <motion.p variants={fade}>
        Social Media: <br></br> 
        <button onClick={directToTwitter}>Twitter</button> <button onClick={directToFB}>Facebook</button> <button onClick={directToLI}>LinkedIn</button>
        </motion.p>
      </Description>
      <Image>
        <motion.img variants={photoAnim} src={home1} alt="Me" />
      </Image>
      <Wave />
    </About>
  );
};

//Styled Components

export default AboutSection;
